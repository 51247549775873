<template>
	<div>
		<Header style="margin-bottom: 20px;" title="兑换会员生成记录详情" @back="onBack()"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="卡号" name="cardNo" class="ui-form__item">
						<a-input v-model:value="formState.cardNo" placeholder="请输入卡号"></a-input>
					</a-form-item>
					
					<a-form-item label="领取状态" name="archiveStatus" class="ui-form__item">
						<a-select v-model:value="formState.archiveStatus" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="0">未领取</a-select-option>
							<a-select-option :value="1">已领取</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="领取用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入领取用户信息"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="领取时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="specialMemberArchiveCodeExportStrategy" :searchData="{recordId: id, ...searchData}"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'archiveStatus'">
							{{ record.archiveStatus ? '已领取' : '未领取' }}
						</template>
						<template v-if="column.key === 'archiveTime'">
							{{ record.archiveTime ? transDateTime(record.archiveTime) : '-' }}
						</template>
						<template v-if="column.key === 'user'">
							<div v-if="record.archiveStatus">
								<div>用户id：{{ record.archiveUserId || '-' }}</div>
								<div>手机号：{{ record.archiveUserPhone || '-' }}</div>
								<div>手机昵称：{{ record.archiveUserNickname || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['user_member_generate_detailDisabled']" v-if="record.isDisabled" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['user_member_generate_detailDisabled']" v-else @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		disabledGeneratorList,
		getGeneratorList
	} from "@/service/modules/member.js";
	export default {
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		components: {
			Icon,
			Header,
			exportReport
		},
		data() {
			return {
				searchData: {},
				loading: false,
				showAll: false,
				formState: {},
				time: [],
				searchData: {},
				columns: [{
					title: '卡号',
					dataIndex: 'cardNo',
				}, {
					title: '卡密',
					dataIndex: 'cardPass',
				}, {
					title: '领取状态',
					key: 'archiveStatus',
					width: 120
				}, {
					title: '领取时间',
					key: 'archiveTime',
				}, {
					title: '领取用户信息',
					key: 'user',
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				list: [],
				pagination: {
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onSearch(){
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.archiveStartTime = this.time[0].startOf('days').unix();
					this.searchData.archiveEndTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.time = [];
				this.onSearch();
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				let ret = await getGeneratorList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					recordId: this.id,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该数据吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await disabledGeneratorList({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功！');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>